.App {
  margin: 0;
 /* text-align: center;*/
  background-color: white;
}

.App-header {
  text-align: center;
  background-color: #009DE0;
  padding: 2%;
}

.btn-submit {
  background-color: rgb(0, 157, 224);
  color: white;
}

.btn-submit:hover {
  background-color: rgb(0, 127, 188);
  color: white;
}
 .Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 35vh;
}

.Users-form-container{
  display: flex;
 /* justify-content: center;
  align-items: center;*/
}

.Filter-form-container{
  display: flex;
 /* justify-content: center;*/
  align-items: right;
}

.Filter-form, .Users-form, .Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.User-form-content, .Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
  text-align: center;
}

.Auth-form-title {
 /* text-align: center;*/
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}
.App-logo {
  width: 25%;
}

.bad-response {
  color: red;
}

.ico-username {
  background: url(./img/person-circle.svg) no-repeat scroll 5px 6px;   
}

.ico-password {
  background: url(./img/lock-fill.svg) no-repeat scroll 5px 6px;   
}

.ico-username, .ico-password {
  background-size: 1.5rem;
}
/* paginador */
.paginator {
  float: right;
}
/* botón sort de ordenación ascendente o descendente */
.btn-sort {
  background-color: #F2F2F2;
  border:none;
  padding: 0;
  margin: 0;
  width:19px;
  height:19px;
  
}
.btn-sort:hover {
  background-color: #DEE2E6;
  cursor: pointer;
}

/* selectores de columnas*/

#borrador {
  display: none;
}

/* link a deviceid */
.deviceid {
  color: #009DE0;
  text-decoration: none;
}

.deviceid:hover {
  color: darkcyan;
  cursor: pointer;
}

/* NavItems */

span.nav-link.nav-button.selected {
  color: #009DE0 !important;
} 

span.nav-link.nav-button {
  display: flex;
  align-items: center;
}

span.nav-link.nav-button svg {
  margin-right: 7px;
}

@media only screen and (max-width: 980px) {
  .Auth-form {
      width: 420px;
      box-shadow: rgba(255, 255, 255, 0.16) 1px 1px 10px;
      padding-top: 30px;
      padding-bottom: 0px;
      border-radius: 0px;
      background-color: white;
  }
}
/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
