  .no-event-click {
      pointer-events: none; 
  }
  body {
      font-size: .875rem;
  }

  #red {
    background-color: red !important;
  }
    
  .feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
  }
  
  /*
   * Sidebar
   */
  
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }

  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  
  .sidebar .nav-link {
    color: #333;
    cursor: pointer;
    font-weight: 500;
  }
  
  .nav-button:hover {
    color: #009DE0;
  }
  
  .sidebar .nav-link .feather {
    margin-right: 4px;
    color: #727272;
  }
  
  .sidebar .nav-link.active {
    color: #2470dc;
  }
  
  .sidebar .nav-link:hover .feather,
  .sidebar .nav-link.active .feather {
    color: inherit;
  }
  
  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }

  /*
  * Navbar
  */
  .navbar {
    /* background-color: #c77f7f !important;*/
    height: 60px !important;
  }
  
  .navbar-nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 60px;
    width: 150px; /* Puedes ajustar este valor según tus necesidades */
    padding: 20px;
    background-color: transparent;
  }

  .nav-items {
    margin-top: 25% !important;
    margin-left: 5%;
  }
  
  .user-item {
    color: rgb(255, 255, 255) !important;
  }
  .user-item:hover {
    cursor: pointer;
    color: rgb(196, 195, 195) !important;
  }
  
  .logout-toggle {
    position: absolute !important;
    padding: 0 !important;
    top: 95% !important;
    z-index: 1 !important; /* Asegura que el botón esté por encima del contenido */
  }
  
  .logout-button{
    padding: 0 !important;
    text-align: center !important;
  }

  .logout-button:active {
    margin: 0 !important;
    background-color: #c77f7f !important;
  }
  
  .navbar-brand {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* O ajusta el valor según tus necesidades */
    padding: 40px;
    z-index: 1; 
    font-size: 1rem;
  }

    
  .App-in-logo{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* O ajusta el valor según tus necesidades */
    padding: 10% 10% 1% 10%;
    z-index: 1; 
  }
  
  
  .navbar-ferrimax {
    background-color: #009DE0;
  }
  
  .navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
  }
  
  .hr {
    border-top: none !important;
    background-color: #009DE0;
  }
  
  .navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }
  
  .form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
  }
  
  .form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
  }

  .bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  .circular-progress {
    position: absolute;
    top: 50%; /* Alinea el elemento hacia el centro vertical */
    left: 50%; /* Alinea el elemento hacia el centro horizontal */
    transform: translate(-50%, -50%); 
  }